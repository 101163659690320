// import s from './Analytics.module.scss';
import Header from '../../components/Header/Header';

const Analytics = () => {
  return (
    <>
      <Header
        title="Аналитика"
        breadCrumbs={[
          { name: 'Главная', link: '/' },
          { name: 'Аналитика', link: '/analytics' },
        ]}
      />
      <div>Аналитика</div>
    </>
  );
};

export default Analytics;
