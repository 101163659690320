import { useEffect, useState } from 'react';
import s from './Auth.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../components/Input/Input';
import { checkAuth, login, signup } from '../../store/auth/actionCreators';
import { useNavigate } from 'react-router-dom';
import formValidator from '../../utils/formValidator';

const Auth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuth, loading, error, isRegistred } = useSelector(
    (state) => state.auth
  );

  const [isLoginForm, setIsLoginForm] = useState(true);
  const [btnSubmit, setBtnSubmit] = useState('Войти');

  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    if (isRegistred) {
      dispatch(login(data));
    }
    // eslint-disable-next-line
  }, [isRegistred]);

  useEffect(() => {
    isAuth && navigate('/');
  }, [isAuth, navigate]);

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  useEffect(() => {
    error && console.log('error', error);
  }, [error]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = formValidator(data, {
      email: ['required', 'length'],
      password: ['required', 'length'],
    });
    if (!!Object.keys(errors).length) {
      setErrors(errors);
      return console.log(errors);
    }
    // login
    if (isLoginForm) {
      dispatch(login(data));
    }
    // signup
    else {
      dispatch(signup(data));
    }
  };

  useEffect(() => {
    if (isLoginForm) {
      if (loading) {
        setBtnSubmit('Вход...');
      } else if (error) {
        setBtnSubmit('Ошибка');
        setTimeout(() => {
          setBtnSubmit('Войти');
        }, 3000);
      } else {
        setBtnSubmit('Войти');
      }
    } else {
      if (loading) {
        setBtnSubmit('Регистрация...');
      } else if (error) {
        setBtnSubmit('Ошибка');
        setTimeout(() => {
          setBtnSubmit('Зарегистрироваться');
        }, 3000);
      } else {
        setBtnSubmit('Зарегистрироваться');
      }
    }
  }, [isLoginForm, loading, error]);

  const changeData = (key, val) => {
    if (errors[key]) {
      setErrors((prev) => {
        const errors = prev;
        delete errors[key];
        return errors;
      });
    }

    setData((prev) => ({ ...prev, [key]: val }));
  };

  return (
    <div className={s.auth}>
      <form onSubmit={handleSubmit} className={s.authForm}>
        <h3 className={s.title}>{!isLoginForm ? 'Регистрация' : 'Вход'}</h3>
        <Input
          name="email"
          label="Почта"
          placeholder=""
          val={data.email}
          className={s.input}
          error={errors.email}
          setVal={(val) => changeData('email', val)}
        />
        <Input
          name="password"
          label="Пароль"
          placeholder=""
          type="password"
          className={s.input}
          val={data.password}
          error={errors.password}
          setVal={(val) => changeData('password', val)}
        />

        <button
          disabled={btnSubmit === 'Ошибка' || loading}
          className={'btn dark ' + s.btnSumbit}
        >
          {btnSubmit}
        </button>

        <span className={s.link} onClick={() => setIsLoginForm((v) => !v)}>
          {!isLoginForm ? 'Войти' : 'Зарегистрироваться'}
        </span>
      </form>
    </div>
  );
};

export default Auth;
