const homeIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.690857 11.7035H2.31993V18.2198C2.31993 18.6038 2.63411 18.9179 3.0181 18.9179H7.78894C8.17293 18.9179 8.48711 18.6038 8.48711 18.2198V13.4489H11.3961V18.2198C11.3961 18.6038 11.7103 18.9179 12.0943 18.9179H16.6324C17.0164 18.9179 17.3306 18.6038 17.3306 18.2198V11.7035H19.3087C19.588 11.7035 19.844 11.529 19.9487 11.273C20.0534 11.017 19.9952 10.7144 19.7974 10.5166L10.698 1.28916C10.4303 1.02151 9.98817 1.00989 9.72052 1.27754L0.213802 10.505C0.00435151 10.7028 -0.053843 11.0053 0.0508824 11.273C0.155608 11.5406 0.411589 11.7035 0.690857 11.7035ZM10.186 2.76694L17.6331 10.3072H16.6324C16.2484 10.3072 15.9342 10.6213 15.9342 11.0053V17.5216H12.7924V12.7508C12.7924 12.3668 12.4783 12.0526 12.0943 12.0526H7.78894C7.40494 12.0526 7.09077 12.3668 7.09077 12.7508V17.5216H3.71627V11.0053C3.71627 10.6213 3.40209 10.3072 3.0181 10.3072H2.41304L10.186 2.76694Z"
      fill="#555555"
    />
  </svg>
);

export default homeIcon;
