const arrowSelectIcon = (
  <svg
    width="10"
    height="5"
    viewBox="0 0 10 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.85707 4.74409C9.63206 5.03813 9.22165 5.0858 8.9404 4.85057L5 1.55496L1.0596 4.85057C0.778346 5.0858 0.36794 5.03813 0.142935 4.74409C-0.0820704 4.45005 -0.0364695 4.02098 0.244787 3.78575L4.59259 0.149407C4.83078 -0.0498023 5.16922 -0.0498023 5.40741 0.149407L9.75521 3.78575C10.0365 4.02098 10.0821 4.45005 9.85707 4.74409Z"
      fill="#555555"
    />
  </svg>
);

export default arrowSelectIcon;
