import { store } from '..';
import api from '../../api';
// import { isTokenExpired } from '../../utils/jwt';
import {
  loginStart,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  signupStart,
  signupSuccess,
  signupFailure,
  checkSuccess,
  checkFailure,
} from './authReducer';

const setTokenLS = (token) => {
  localStorage.setItem('accessToken', JSON.stringify(token));
};

export const signup = (data) => async (dispatch) => {
  try {
    dispatch(signupStart());
    const extData = { ...data, username: data.email };
    const res = await api.auth.signup(extData);
    if (res.data.message === 'Registered') {
      dispatch(signupSuccess());
    } else {
      throw new Error('Непредвиденная ошибка');
    }
  } catch (e) {
    console.error(e.message);
    // const detail = e?.response?.data;
    dispatch(signupFailure(e.message));
  }
};

export const login = (data) => async (dispatch) => {
  try {
    dispatch(loginStart());
    const res = await api.auth.login(data);
    if (res.data.message === 'Authorized') {
      setTokenLS(res.data.access_token);
      dispatch(loginSuccess(res.data.access_token));
    } else {
      throw new Error('Непредвиденная ошибка');
    }
  } catch (e) {
    console.error(e.message);
    dispatch(loginFailure(e.message));
  }
};

export const logout = () => async (dispatch) => {
  try {
    console.log('logout');
    localStorage.clear();
    dispatch(logoutSuccess());
  } catch (e) {
    console.error(e);
  }
};

export const checkAuth = () => async (dispatch) => {
  try {
    await api.auth.checkToken();
    dispatch(checkSuccess());
  } catch (e) {
    console.error(e);
    dispatch(checkFailure());
  }
};

// let refreshTokenRequest;
export const getAccessToken = () => async (dispatch) => {
  try {
    const accessTokenLS = localStorage.getItem('accessToken');
    // const refreshTokenLS = localStorage.getItem('refreshToken');
    // check Redux Store
    let accessToken = store.getState().auth.accessToken;
    // check LS
    if (!accessToken) {
      accessToken = accessTokenLS ? JSON.parse(accessTokenLS) : '';
      // const refreshToken = refreshTokenLS ? JSON.parse(refreshTokenLS) : '';
      // if (accessToken && refreshToken) {
      //   dispatch(
      //     loginSuccess({
      //       jwt: accessToken,
      //       refresh_token: refreshToken,
      //     })
      //   );
      // }
    }
    // check Refresh Token
    // if (!accessToken || isTokenExpired(accessToken)) {
    //   const refreshToken = refreshTokenLS ? JSON.parse(refreshTokenLS) : '';
    //   if (!refreshToken) {
    //     throw new Error();
    //   }
    //   if (!refreshTokenRequest) {
    //     refreshTokenRequest = await api.auth.refreshToken({
    //       token: refreshToken,
    //     });
    //   }
    //   console.log('refreshTokenRequest', refreshTokenRequest);
    //   if (refreshTokenRequest.data) {
    //     // setTokenLS(refreshTokenRequest.data);
    //     dispatch(loginSuccess(refreshTokenRequest.data));
    //     const jwt = refreshTokenRequest.data.jwt;
    //     refreshTokenRequest = undefined;
    //     accessToken = jwt;
    //     return jwt;
    //   } else {
    //     throw new Error();
    //   }
    // }
    if (!accessToken) {
      throw new Error();
    }
    return accessToken;
  } catch (e) {
    console.log('AccessToken NotFound');
    logout();
    return null;
  }
};
