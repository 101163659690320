import Popup from './Popup';
import { useState } from 'react';
import s from './Popup.module.scss';

const PopupAgreement = ({
  errMes,
  label,
  close,
  agree,
  closeName = 'Отмена',
  agreeName = 'Подтвердить',
}) => {
  const [brandVal, setBrandVal] = useState('');

  return (
    <Popup
      close={close}
      className={s.popupAgreement + ' ' + (errMes ? s.withError : '')}
      windowClassName={s.popupAgreementWindow}
    >
      <h3 className={s.title}>{label}</h3>
      <div className={s.btns}>
        <button onClick={() => close()} className={'btn light'}>
          {closeName}
        </button>
        <button onClick={() => agree(brandVal)} className={'btn  dark'}>
          {agreeName}
        </button>
      </div>
      {errMes && <p className={s.errMes}>{errMes}</p>}
    </Popup>
  );
};

export default PopupAgreement;
