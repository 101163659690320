// import api from '../../api/index.js';
import { useNavigate } from 'react-router-dom';
import s from './Categories.module.scss';
// import arrowIcon from '../../assets/icons/arrow.js';
// import Loader from '../../components/Loader/Loader.jsx';

const CatProductItem = ({ prod }) => {
  const navigate = useNavigate();
  const getPercentClass = (percent) => {
    if (percent < 50) return s.red;
    else if (percent < 75) return s.yellow;
    else return s.green;
  };

  return (
    <li className={s.catWrap}>
      <div className={[s.cat, prod.has_master ? s.active : ''].join(' ')}>
        <div className={s.catNameWrap}>
          {/* {prod.percent && (
            <p className={s.catPercent + ' ' + getPercentClass(prod.percent)}>
              {prod.percent}%
            </p>
          )} */}
          <h3 className={s.catName}>{prod.title}</h3>
          <div className={s.btns}></div>
        </div>
        <ul className={s.catStickers}>
          {prod.api_name && (
            <li className={s.sticker + ' ' + s.yellow}>api: {prod.api_name}</li>
          )}
          {prod._id && (
            <li className={s.sticker + ' ' + s.violet}>id: {prod._id}</li>
          )}
        </ul>
      </div>
    </li>
  );
};

export default CatProductItem;
