import s from './Products.module.scss';
import ProductItem from './ProductItem';
import SubProductItem from './SubProductItem';
import Loader from '../../components/Loader/Loader';
import Search from '../../components/Search/Search';

const ProductList = ({
  loading,
  products,
  toMaster,
  delMaster,
  // allProducts,
  subLoading,
  changeStatus,
  subProducts,
  activeProduct,
  setActiveProduct,
  searchVal,
  setSearchVal,
}) => {
  // const [similarShowed, setSimilarShowed] = useState(false);

  // const getPercentClass = (percent) => {
  //   if (percent < 50) return s.red;
  //   else if (percent < 75) return s.yellow;
  //   else return s.green;
  // };

  return (
    <div className={s.productsWrap}>
      <div className={s.products}>
        {!loading ? (
          <>
            <p className={s.masterNum}>
              {!!products?.length &&
                `Мастеров${products.length ? ': ' + products.length : ' нет'}`}
            </p>
            <ul className={s.itemsList + ' ' + s.main}>
              {products.map((product, i) => (
                <ProductItem
                  key={i}
                  product={product}
                  delMaster={delMaster}
                  activeProduct={activeProduct}
                  setActiveProduct={setActiveProduct}
                />
              ))}
              {products.length === 0 && <p>Пусто</p>}
            </ul>
          </>
        ) : (
          <Loader />
        )}
      </div>

      <div className={s.right}>
        <div className={s.searchWrap}>
          <div className={s.titleWrap}>
            <h3 className={s.title}>
              {activeProduct.title ? (
                <>
                  <span className={s.primary}>Сопоставить c </span>
                  <span>{activeProduct.title}</span>
                </>
              ) : (
                <span className={s.primary}>Сопоставить товары</span>
              )}
            </h3>
            {/* {categoryLvl && (
              <Select
                className={s.select}
                selectedItem={categoryLvl}
                handleSelect={setCategoryLvl}
                list={catLvls}
              />
            )} */}
          </div>
          <Search
            val={searchVal}
            className={s.search}
            setVal={setSearchVal}
            placeholder={'Поиск товара'}
          />
        </div>
        {/* {activeProduct._id ? ( */}
        <div className={s.subProducts}>
          {!subLoading ? (
            <ul
              className={
                s.itemsList + ' ' + (subProducts.length === 0 ? s.empty : '')
              }
            >
              {subProducts.map((product, i) => (
                <SubProductItem
                  key={i}
                  product={product}
                  toMaster={toMaster}
                  changeStatus={changeStatus}
                  activeProduct={activeProduct}
                />
              ))}
              {subProducts.length === 0 && <p>Пусто</p>}
            </ul>
          ) : (
            <Loader />
          )}
        </div>
        {/* //  ) : (
        //   <div className={s.subProductsEmpty}>
        //     <h2 className={s.title}>
        //       Выберите товар, чтобы начать устанавливать соответствие
        //     </h2>
        //   </div>
        // )}  */}
      </div>
    </div>
  );
};

export default ProductList;
