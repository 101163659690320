// import api from '../../api/index.js';
import { useLocation, useNavigate } from 'react-router-dom';
import s from './Categories.module.scss';
// import arrowIcon from '../../assets/icons/arrow.js';
// import Loader from '../../components/Loader/Loader.jsx';

const SubCategoryItem = ({
  category,
  activeCategory,
  bindCategory,
  isDetailPage,
}) => {
  const location = useLocation()
  // const navigate = useNavigate();
  const getPercentClass = (percent) => {
    if (percent < 50) return s.red;
    else if (percent < 75) return s.yellow;
    else return s.green;
  };

  return (
    <li className={s.catWrap}>
      <div
        // title={category.has_master ? 'Убрать соответствие' : ''}
        className={[s.cat, s.detail, category.has_master ? s.active : ''].join(
          ' '
        )}
        // onClick={() => bindCategory(category)}
      >
        <div className={s.catNameWrap}>
          {category.percent && (
            <p
              className={s.catPercent + ' ' + getPercentClass(category.percent)}
            >
              {category.percent}%
            </p>
          )}
          <h3 className={s.catName}>{category.category_name}</h3>
          <div className={s.btns}>
            <button
              onClick={() => bindCategory(category)}
              className={
                'btn small ' + ' ' + (category.has_master ? 'red' : 'dark')
              }
            >
              {category.has_master ? 'Удалить связь' : 'Cоотнести'}
            </button>

            {isDetailPage && (
              <a href={`${location.pathname}/${category._id}/products`} target='_blank' className={'btn small light'}>
                Показать товары
              </a>
              // <button
              //   onClick={() => navigate(`${category._id}/products`)}
              //   className={'btn small light'}
              // >
              //   Показать товары
              // </button>
            )}
          </div>
        </div>
        <ul className={s.catStickers}>
          {category.api_name && (
            <li className={s.sticker + ' ' + s.yellow}>
              api: {category.api_name}
            </li>
          )}
          {category._id && (
            <li className={s.sticker + ' ' + s.violet}>id: {category._id}</li>
          )}
        </ul>
      </div>
    </li>
  );
};

export default SubCategoryItem;
