import api from '../../api/index.js';
import s from './Categories.module.scss';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CatProductsList from './CatProductsList.jsx';
import Header from '../../components/Header/Header.jsx';

const CatProducts = () => {
  const { cat_id, subcat_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [categories, setCategories] = useState([]);
  const [subLoading, setSubLoading] = useState(false);

  console.log(subcat_id);

  const [catData, setCatData] = useState({
    category_name: '',
  });
  const [subCatData, setSubCatData] = useState({
    category_name: '',
  });

  const [products, setProducts] = useState([]);
  // const [categoryLvl, setCategoryLvl] = useState();
  const [activeCategory, setActiveCategory] = useState({});
  const [sortedProducts, setSortedProducts] = useState([]);

  const sortProducts = (val) => {
    setSortedProducts([
      ...products.filter((prod) =>
        prod.title.toLowerCase().includes(searchVal.toLowerCase())
      ),
    ]);
  };

  useEffect(() => {
    if (searchVal) {
      sortProducts(searchVal);
    } else {
      setSortedProducts([...products]);
    }
  }, [searchVal, products]);

  const getProducts = async () => {
    if (!subcat_id) return;
    setSubLoading(true);
    const data = await api.products.getListBySubCat({
      subcat_id: subcat_id,
    });
    if (data.goods) {
      setProducts(data.goods);
    }
    setSubLoading(false);
  };

  const getSubCategoryName = async () => {
    if (!cat_id) return;
    const catData = await api.categories.getCategoryById(cat_id);
    const subcatData = await api.categories.getCategoryById(subcat_id);
    if (catData?.category) {
      setCatData(catData.category);
    }
    if (subcatData?.category) {
      setSubCatData(subcatData.category);
    }
  };

  useEffect(() => {
    getProducts();
    getSubCategoryName();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={s.page}>
      <Header
        btnBack={{ name: 'назад к категориям', link: '/categories' }}
        title={`Товары подкатегории ${subCatData.category_name}`}
        breadCrumbs={[
          { name: 'Главная', link: '/' },
          { name: 'Категории', link: '/categories' },
          {
            name: `Категория ${catData.category_name}`,
            link: `/categories/${catData.api_category_id}`,
          },
          {
            name: `Товары`,
            link: '/',
          },
        ]}
      />

      <CatProductsList
        prodLvls={[]}
        loading={loading}
        searchVal={searchVal}
        subCatData={subCatData}
        categories={categories}
        subLoading={subLoading}
        products={sortedProducts}
        setSearchVal={setSearchVal}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
      />
    </div>
  );
};

export default CatProducts;
