import s from './Popup.module.scss';
import Loader from '../Loader/Loader';
import { useEffect, useRef } from 'react';
import closeIcon from '../../assets/icons/closeIcon';

// type PopupProps = {
//     children: ReactNode;
//     close: () => void;
//     title?: string;
//     className?: string;
// };

const Popup = ({
  close,
  loading,
  children,
  title = '',
  className = '',
  windowClassName = '',
}) => {
  const windowRef = useRef(null);

  useEffect(() => {
    const html = document.querySelector('html');
    if (!html) return;
    html.style.overflowY = 'hidden';
    return () => {
      html.style.overflowY = 'scroll';
    };
  }, []);

  useEffect(() => {
    const onClick = (e) => {
      if (windowRef.current) {
        if (!windowRef.current.contains(e.target)) {
          close();
        }
      }
    };
    setTimeout(() => {
      document.addEventListener('click', onClick);
    }, 50);
    return () => document.removeEventListener('click', onClick);
  }, [close]);

  return (
    <div className={s.wrap}>
      <div
        className={
          s.window + ' ' + windowClassName + ' ' + (loading ? s.loading : '')
        }
        ref={windowRef}
      >
        <button onClick={close} className={s.btnClose}>
          {closeIcon}
        </button>
        {title && <h2 className={s.title}>{title}</h2>}
        <div className={s.content + ' ' + className}>{children}</div>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default Popup;
