// const URI = process.env.REACT_APP_API_URI;
const URI = process.env.REACT_APP_API_URI;

const Endpoints = {
  AUTH: {
    LOGOUT: URI + 'logout',
    REFRESH: URI + 'refresh_token',
    LOGIN: URI + 'auth/user/login',
    SIGNUP: URI + 'auth/user/register',
    CHECK: URI + 'auth/user/token/check',
  },
  PRODUCTS: {
    GET_LIST: URI + 'items',
    GET_LIST_BY_BRAND: URI + 'items',
    MAKE_MASTER: URI + 'items/master',
    DEL_MASTER: URI + 'items/master',
    UPDATE_STATUS: URI + 'items/status',
  },
  CATEGORIES: {
    GET_BY_ID: URI + 'categories',
    GET_ALL: URI + 'categories/all',
    GET_MASTER_LIST: URI + 'categories/first_level/master',
    GET_FIRST_LIST: URI + 'categories/first_level',
    GET_FIRST_SUB_LIST: URI + 'categories',
    GET_ALL_SUB_LIST: URI + 'categories/subcategories/all',
    // OLD
    GET_LIST: URI + 'categories/first_level',
    GET_BX_LIST: URI + 'categories/bitrix/first_level',
    GET_BX_SUB_LIST: URI + 'categories/bitrix/first_level',
    COMPARE: URI + 'categories/compare',
    COMPARE_DEL: URI + 'categories/compare',
    GET_WG_LIST: URI + 'categories/first_level', //
    // GET_WG_LIST: URI + 'categories/first_level/master',
    GET_WG_SLAVES_LIST: URI + 'categories',
  },
  BRANDS: {
    GET_LIST: URI + 'brands/all',
    GET_COMPARE_LIST: URI + 'brands/compare/auto',
    // GET_WG_LIST_BY_CAT: URI + 'categories/subcategories',
    GET_LIST_BY_CAT: URI + 'categories/subcategories',
  },
};

export default Endpoints;
