import Input from '../Input/Input';
import s from './Search.module.scss';

const Search = ({ val, setVal, placeholder, className }) => {
  return (
    <div className={s.search + ' ' + className}>
      <Input
        //   unit
        //   error
        //   label
        val={val}
        setVal={setVal}
        name="category"
        className={s.input}
        placeholder={placeholder}
      />
    </div>
  );
};

export default Search;
