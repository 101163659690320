import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../store';
import { Outlet, useNavigate } from 'react-router-dom';
import { checkAuth } from '../store/auth/actionCreators';

const AuthLayout = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAuth, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(checkAuth());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isAuth && !loading) {
      navigate('/auth');
    }
    // eslint-disable-next-line
  }, [isAuth, loading]);

  return <main className="main">{isAuth && <Outlet />}</main>;
};

export default AuthLayout;
