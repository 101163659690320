import { useEffect, useState } from 'react';
import api from '../../api/index.js';
import s from './Products.module.scss';
import Loader from '../../components/Loader/Loader.jsx';
import Header from '../../components/Header/Header.jsx';
import { useNavigate } from 'react-router-dom';
import Search from '../../components/Search/Search.jsx';

const ProductCategories = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [catSearch, setCatSearch] = useState('');
  const [categories, setCategories] = useState([]);
  const [subCatSearch, setSubCatSearch] = useState('');
  const [mainCategory, setMainCategory] = useState({});
  const [subCategories, setSubCategories] = useState([]);
  const [splittedListObj, setSplittedListObj] = useState({});
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);

  const getCategories = async () => {
    const data = await api.categories.getMasterList();
    // const data = await api.categories.getBXList();
    if (data.categories) {
      setCategories(sorting(data.categories, 'category_name', 1));
    }
  };

  const getSubCategories = async (api_category_id) => {
    setLoading(true);
    const data = await api.categories.getFirstSubList(api_category_id);
    // const data = await api.categories.getBXSubList(api_category_id);
    if (data.categories) {
      setSubCategories(data.categories);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (mainCategory.api_category_id) {
      getSubCategories(mainCategory.api_category_id);
    } else {
      setSubCategories([]);
    }
  }, [mainCategory]);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (catSearch) {
      setFilteredCategories([
        ...categories.filter((cat) =>
          cat.category_name?.toLowerCase().includes(catSearch.toLowerCase())
        ),
      ]);
    } else {
      setFilteredCategories([...categories]);
    }
  }, [categories, catSearch]);

  useEffect(() => {
    if (subCatSearch) {
      setFilteredSubCategories([
        ...subCategories.filter((cat) =>
          cat.category_name?.toLowerCase().includes(subCatSearch.toLowerCase())
        ),
      ]);
    } else {
      setFilteredSubCategories([...subCategories]);
    }
  }, [subCategories, subCatSearch]);

  const setCategory = (cat) => {
    const subcat_id = cat?.api_category_id;
    const cat_id = mainCategory?.api_category_id;
    if (mainCategory?.api_category_id && cat?.api_category_id) {
      navigate(`/products/${cat_id}/${subcat_id}`);
    }
  };

  const sorting = (list, listKey, sortKey) => {
    return list.sort((a, b) => {
      if (sortKey === 1) {
        if (a[listKey].toLowerCase() < b[listKey].toLowerCase()) {
          return -1;
        }
        if (b[listKey].toLowerCase() > a[listKey].toLowerCase()) {
          return 1;
        }
        return 0;
      } else {
        if (a[listKey].toLowerCase() > b[listKey].toLowerCase()) {
          return -1;
        }
        if (b[listKey].toLowerCase() < a[listKey].toLowerCase()) {
          return 1;
        }
        return 0;
      }
    });
  };

  const splitIntoArrays = (list, arrN) => {
    const resObj = {};
    const itemsInList = Math.floor(list.length / arrN);
    let arrCount = 0;
    for (let item of list) {
      if (resObj[arrCount] && resObj[arrCount].length === itemsInList) {
        arrCount++;
      }
      if (resObj[arrCount]) {
        resObj[arrCount].push(item);
      } else {
        resObj[arrCount] = [item];
      }
    }
    return resObj;
  };

  useEffect(() => {
    const sortedList = [...sorting(filteredSubCategories, 'category_name', 1)];
    setSplittedListObj(splitIntoArrays(sortedList, 3));
  }, [filteredSubCategories]);

  return (
    <div className={s.page}>
      <Header
        title={
          'Сопоставление товаров. ' +
          (mainCategory.category_name
            ? `Категория: ${mainCategory.category_name}`
            : 'Категории')
        } //+ (category ? ': ' + category.category_name : '')
        breadCrumbs={[
          { name: 'Главная', link: '/' },
          {
            name: 'Сопоставление товаров',
            link: '/products',
          },
        ]}
      />
      <div className={s.categoriesContainer}>
        <div className={s.categoriesWrap}>
          <div className={s.categories}>
            <Search
              val={catSearch}
              setVal={setCatSearch}
              className={s.catSearch}
              placeholder={'Поиск категорий'}
            />
            <ul className={s.itemsList}>
              {filteredCategories.map((cat, i) => (
                <li
                  key={i}
                  onClick={
                    () => {
                      setSubCatSearch('');
                      setMainCategory(cat);
                    }
                    // mainCategory.api_category_id !== cat.api_category_id
                    //   ? setMainCategory(cat)
                    //   : setCategory(cat)
                  }
                  className={
                    s.item +
                    ' ' +
                    (mainCategory.api_category_id === cat.api_category_id
                      ? s.active
                      : '')
                  }
                >
                  {cat.category_name}
                </li>
              ))}
            </ul>
          </div>
          <div className={s.subCategories}>
            {mainCategory._id && (
              <Search
                val={subCatSearch}
                className={s.catSearch}
                setVal={setSubCatSearch}
                placeholder={'Поиск подкатегорий'}
              />
            )}
            {!loading ? (
              <ul className={s.itemsList}>
                <ul className={s.itemsSubList}>
                  {splittedListObj[0] &&
                    splittedListObj[0].map((cat, i) => (
                      <li
                        key={i}
                        onClick={() => setCategory(cat)}
                        className={s.item}
                      >
                        {cat.category_name}
                      </li>
                    ))}
                </ul>
                <ul className={s.itemsSubList}>
                  {splittedListObj[1] &&
                    splittedListObj[1].map((cat, i) => (
                      <li
                        key={i}
                        onClick={() => setCategory(cat)}
                        className={s.item}
                      >
                        {cat.category_name}
                      </li>
                    ))}
                </ul>
                <ul className={s.itemsSubList}>
                  {splittedListObj[2] &&
                    splittedListObj[2].map((cat, i) => (
                      <li
                        key={i}
                        onClick={() => setCategory(cat)}
                        className={s.item}
                      >
                        {cat.category_name}
                      </li>
                    ))}
                </ul>
              </ul>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCategories;
