// import api from '../../api/index.js';
import { Link } from 'react-router-dom';
import s from './Categories.module.scss';
// import arrowIcon from '../../assets/icons/arrow.js';
// import Loader from '../../components/Loader/Loader.jsx';

const CategoryItem = ({
  category,
  isDetailPage,
  activeCategory,
  setActiveCategory,
}) => {
  // const getPercentClass = (percent) => {
  //   if (percent < 50) return s.red;
  //   else if (percent < 75) return s.yellow;
  //   else return s.green;
  // };

  return (
    <li className={s.catWrap}>
      <div
        className={
          s.cat +
          ' ' +
          s.main +
          ' ' +
          (category.api_category_id === activeCategory.api_category_id
            ? s.active
            : '')
        }
      >
        <div className={s.catNameWrap}>
          <h3 className={s.catName}>
            {category.category_name}
            {category.slaves && (
              <span className={s.catNumb}>
                {' (' + category.slaves.length + ') '}{' '}
              </span>
            )}
          </h3>
          <ul className={s.catStickers}>
            {category._id && (
              <li className={s.sticker + ' ' + s.blue}>id: {category._id}</li>
            )}
            {category.api_name && (
              <li className={s.sticker + ' ' + s.yellow}>
                api: {category.api_name}
              </li>
            )}
          </ul>
        </div>
        <div className={s.btns}>
          <button
            className={'btn light small ' + s.btn}
            onClick={(e) => {
              e.stopPropagation();
              activeCategory.api_category_id === category.api_category_id
                ? setActiveCategory({})
                : setActiveCategory(category);
            }}
          >
            {activeCategory.api_category_id === category.api_category_id
              ? 'Отмена'
              : 'Соотнести'}
          </button>
          {!isDetailPage && (
            <Link
              to={`/categories/${category._id}`}
              className={'btn light small ' + s.btn}
            >
              Перейти
            </Link>
          )}
        </div>
      </div>
    </li>
  );
};

export default CategoryItem;
