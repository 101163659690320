import { useDispatch } from 'react-redux';
import authReducer from './auth/authReducer';
import { configureStore } from '@reduxjs/toolkit';

export const store = configureStore({
  reducer: {
    auth: authReducer,
  },
});

export const useAppDispatch = useDispatch;
