import axios from 'axios';
import { store } from '../store';
import Endpoints from './endpoints';
import { useSelector } from 'react-redux';
import { getAccessToken } from '../store/auth/actionCreators';

export const axiosInstance = axios.create({});

const urlsSkipAuth = [
  Endpoints.AUTH.LOGIN,
  Endpoints.AUTH.SIGNUP,
  Endpoints.AUTH.LOGOUT,
  Endpoints.AUTH.REFRESH,
];

axiosInstance.interceptors.request.use(
  async (config) => {
    if (config.url && urlsSkipAuth.includes(config.url)) {
      return config;
    }
    const accessToken = await store.dispatch(getAccessToken());
    if (accessToken) {
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    } else {
      // await store.dispatch(logoutUser());
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    console.log(response);
  },
  (error) => {
    console.log(error);

    const authorized = useSelector(
      (state) => !!state.auth.profileData.authorized
    );
    console.log('authorized', authorized);
    if (
      error.response?.status === 403 &&
      authorized &&
      error.request.url !== Endpoints.AUTH.LOGOUT
    ) {
      // store.dispatch(logoutUser());
    }
    throw error;
  }
);
