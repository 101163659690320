import s from './Header.module.scss';
import { Link } from 'react-router-dom';

const Header = ({ breadCrumbs = [], title, titleIcon, btnBack }) => {
  return (
    <header className={s.headerWrap}>
      <div className={s.header}>
        {/* {btnBack && (
          <Link to={btnBack.link} className={s.btnBack}>
            <span>{btnBack.name}</span>
          </Link>
        )} */}
        <ul className={s.breadCrumbs}>
          {breadCrumbs.length > 0 &&
            breadCrumbs.map((breadCrumb, i) => (
              <Link key={i} to={breadCrumb.link} className={s.breadCrumb}>
                {breadCrumb.name}
              </Link>
            ))}
        </ul>
        <div className={s.titleWrap}>
          {titleIcon ?? ''}
          <h2 className={s.title}>{title}</h2>
        </div>
      </div>
    </header>
  );
};

export default Header;
