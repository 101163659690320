import s from './Categories.module.scss';
import CategoryItem from './CategoryItem';
import CatProductItem from './CatProductItem';
import Loader from '../../components/Loader/Loader';
import Search from '../../components/Search/Search';
import Select from '../../components/Select/Select';

const CatProductsList = ({
  loading,
  prodLvls,
  products,
  searchVal,
  subCatData,
  subLoading,
  categories,
  categoryLvl,
  setSearchVal,
  activeCategory,
  setCategoryLvl,
  setActiveCategory,
}) => {
  // const [similarShowed, setSimilarShowed] = useState(false);

  // const getPercentClass = (percent) => {
  //   if (percent < 50) return s.red;
  //   else if (percent < 75) return s.yellow;
  //   else return s.green;
  // };

  return (
    <div className={s.categoriesWrap}>
      <div className={s.categories}>
        {!loading ? (
          <ul className={s.itemsList}>
            {categories.map((category, i) => (
              <CategoryItem
                key={i}
                category={category}
                activeCategory={activeCategory}
                setActiveCategory={setActiveCategory}
              />
            ))}
          </ul>
        ) : (
          <Loader />
        )}
      </div>

      <div className={s.right}>
        <div className={s.searchWrap}>
          <div className={s.titleWrap}>
            <h3 className={s.title}>
              <span className={s.primary}>
                Товары подкатегории {subCatData.category_name}
              </span>
              {activeCategory.category_name ? (
                <>
                  <span className={s.primary}> c </span>
                  <span>{activeCategory.category_name}</span>
                </>
              ) : (
                ''
              )}
            </h3>
            {categoryLvl && (
              <Select
                className={s.select}
                selectedItem={categoryLvl}
                handleSelect={setCategoryLvl}
                list={prodLvls}
              />
            )}
          </div>
          <Search
            val={searchVal}
            className={s.search}
            setVal={setSearchVal}
            placeholder={'Поиск подкатегорий'}
          />
        </div>
        {/* {activeCategory.api_category_id ? ( */}
        <div className={s.subCategories}>
          {!subLoading ? (
            <ul
              className={
                s.itemsList + ' ' + (products.length === 0 ? s.empty : '')
              }
            >
              {products.map((prod, i) => (
                <CatProductItem key={i} prod={prod} />
              ))}
              {products.length === 0 && <p>Товаров не найдено</p>}
            </ul>
          ) : (
            <Loader />
          )}
        </div>
        {/* ) : (
          <div className={s.subCategoriesEmpty}>
            <h2 className={s.title}>
              Выберите корневую категорию, чтобы начать устанавливать
              соответствие
            </h2>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default CatProductsList;
