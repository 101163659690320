import Endpoints from '../endpoints';
import { axiosInstance } from '../instance';

// new
export const getMasterList = async () => {
  try {
    const res = await axiosInstance.get(Endpoints.CATEGORIES.GET_MASTER_LIST);
    if (!res.data.error) {
      return res.data;
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log(e);
    return { error: 'Не удалось получить список категорий' };
  }
};

export const getAll = async () => {
  try {
    const res = await axiosInstance.get(Endpoints.CATEGORIES.GET_ALL);
    if (!res.data.error) {
      return res.data;
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log(e);
    return { error: 'Не удалось получить список категорий' };
  }
};

export const getFirstSubList = async (cat_id) => {
  try {
    const res = await axiosInstance.get(
      Endpoints.CATEGORIES.GET_FIRST_SUB_LIST + `/${cat_id}/subcategories`
    );
    if (!res.data.error) {
      return res.data;
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log(e);
    return { error: 'Не удалось получить список категорий' };
  }
};

export const getSlavesList = async (category_id) => {
  try {
    const res = await axiosInstance.get(
      Endpoints.CATEGORIES.GET_WG_SLAVES_LIST + `/${category_id}/slaves`
    );
    if (!res.data.error) {
      return res.data;
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log(e);
    return { error: 'Не удалось получить список категорий' };
  }
};

export const getAllSubList = async (id) => {
  try {
    const res = await axiosInstance.get(Endpoints.CATEGORIES.GET_ALL_SUB_LIST);
    if (!res.data.error) {
      return res.data;
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log(e);
    return { error: 'Не удалось получить список категорий' };
  }
};

export const getFirstList = async () => {
  try {
    const res = await axiosInstance.get(Endpoints.CATEGORIES.GET_FIRST_LIST);
    if (!res.data.error) {
      return res.data;
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log(e);
    return { error: 'Не удалось получить список категорий' };
  }
};

export const getCategoryById = async (category_id) => {
  try {
    const res = await axiosInstance.get(
      Endpoints.CATEGORIES.GET_BY_ID + `/${category_id}`
    );
    if (!res.data.error) {
      return res.data;
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log(e);
    return { error: 'Не удалось получить список категорий' };
  }
};

// old

export const getBXList = async () => {
  try {
    const res = await axiosInstance.get(Endpoints.CATEGORIES.GET_BX_LIST);
    if (!res.data.error) {
      return res.data;
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log(e);
    return { error: 'Не удалось получить список категорий' };
  }
};

export const getBXSubList = async (category_id) => {
  try {
    const res = await axiosInstance.get(
      Endpoints.CATEGORIES.GET_BX_SUB_LIST + `/${category_id}/second_level`
    );
    if (!res.data.error) {
      return res.data;
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log(e);
    return { error: 'Не удалось получить список категорий' };
  }
};

export const compare = async (data) => {
  try {
    const res = await axiosInstance.post(
      Endpoints.CATEGORIES.COMPARE,
      {},
      {
        params: data,
      }
    );
    if (res.data.status === 'created') {
      return { created: true };
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log(e);
    return { error: 'Не удалось обновить статус товара' };
  }
};

export const deleteCompare = async (data) => {
  try {
    const res = await axiosInstance.delete(Endpoints.CATEGORIES.COMPARE_DEL, {
      params: data,
    });
    if (res.data.status === 'deleted') {
      return { deleted: true };
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log(e);
    return { error: 'Не удалось отменить соответствие' };
  }
};

const products = {
  getAll,
  compare,
  getBXList,
  getBXSubList,
  deleteCompare,
  getCategoryById,
  // new
  // cats
  getFirstList,
  getMasterList,
  getSlavesList,
  // sub cats
  getFirstSubList,
  getAllSubList,
};

export default products;
