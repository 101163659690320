import { useEffect, useState } from 'react';
import api from '../../api/index.js';
import s from './Brands.module.scss';
import arrowIcon from '../../assets/icons/arrow.js';
import Popup from '../../components/Popup/Popup.jsx';
import Input from '../../components/Input/Input.jsx';
import pencilIcon from '../../assets/icons/pencilIcon.js';

const BrandItem = ({ brand, updateStatusInList }) => {
  // const [similarShowed, setSimilarShowed] = useState(false);
  const [popupEditStatus, setPopupEditStatus] = useState();
  const [statusUpdating, setStatusUpdating] = useState(false);
  const [popupEditShowed, setPopupEditShowed] = useState(false);

  const getPercentClass = (percent) => {
    if (percent < 50) return s.red;
    else if (percent < 75) return s.yellow;
    else return s.green;
  };

  const updateStatus = async (brandItem, val) => {
    if (!brand || !val || !brandItem) return;
    setStatusUpdating(true);
    const body = {
      PK: brand.PK,
      SK: brand.SK,
      status: val,
      internal_sk: brandItem.SK,
    };
    const data = await api.brands.updateStatus(body);
    if (data.error) {
      return console.log('data.error', data.error);
    }
    updateStatusInList(brand, brandItem, val);
    setPopupEditStatus((prev) => ({ ...prev, status: String(val) }));
    setStatusUpdating(false);
  };

  return (
    brand && (
      <li
        className={
          s.brandWrap + (brand.slaves?.length > 0 ? '' : ' ' + s.empty)
        }
      >
        <div
          className={
            s.brand +
            ' ' +
            s.main +
            // ' ' +
            // (similarShowed ? s.showed : '') +
            // ' ' +
            // (brand.slaves.length > 0 ? '' : s.disabled) +
            ' ' +
            s.showed +
            ' ' +
            s.disabled
          }
          // onClick={() => brand.slaves.length > 0 && setSimilarShowed((p) => !p)}
        >
          <div className={s.brandNameWrap}>
            <h3 className={s.brandName}>{brand.brand_name}</h3>

            <ul className={s.brandStickers}>
              <li className={s.sticker + ' ' + s.yellow}>
                api: {brand.api_name}
              </li>
              <li className={s.sticker + ' ' + s.blue}>id: {brand._id}</li>
              {/* <li className={s.sticker + ' ' + s.violet}>id: 50310</li> */}
              {/* <li className={s.sticker + ' ' + s.pink}>кастомное название</li>
            <li className={s.sticker + ' ' + s.red}>название из whitegoods</li> */}
              {/* <li
              onClick={(e) => e.stopPropagation()}
              className={s.sticker + ' ' + s.blue + ' ' + s.add}
            >
              + Создать бренд в битриксе
            </li> */}
            </ul>
            {/* <button
              onClick={(e) => {
                e.stopPropagation();
                setPopupEditShowed(true);
              }}
              className={s.btnEdit}
            >
              {pencilIcon} <span>Редактировать</span>
            </button> */}
          </div>
          <div className={s.similarFoundArrowWrap}>
            <p>
              {brand.slaves?.length > 0
                ? `Соответствующих брендов: ${brand.slaves.length}`
                : 'Соответствующих брендов нет'}
            </p>
            {/* {brand.slaves.length > 0 && (
              <div className={s.similarFoundArrow}>{arrowIcon}</div>
            )} */}
          </div>
        </div>
        {brand.slaves?.length > 0 && (
          <ul className={s.brandItemsWrap}>
            {brand.slaves?.length > 0 &&
              brand.slaves.map((brandItem, i) => (
                <li key={i} className={s.brand}>
                  <div className={s.brandNameWrap}>
                    {/* <p
                      className={
                        s.brandPercent +
                        ' ' +
                        getPercentClass(brandItem.percent)
                      }
                    >
                      {brandItem.percent}%
                    </p> */}
                    <h3 className={s.brandName}>{brandItem.brand_name}</h3>
                    <ul className={s.brandStickers}>
                      {brandItem.api_name && (
                        <li className={s.sticker + ' ' + s.yellow}>
                          api: {brandItem.api_name}
                        </li>
                      )}
                      {brandItem.slave_id && (
                        <li className={s.sticker + ' ' + s.violet}>
                          id: {brandItem.slave_id}
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className={s.statusWrap}>
                    {/* {+brandItem.status > 0 ? (
                      <p
                        className={
                          s.status +
                          ' ' +
                          (+brandItem.status === 1 ? s.green : s.red)
                        }
                      >
                        {+brandItem.status === 1
                          ? 'Соответствует'
                          : 'Не соответствует'}
                      </p>
                    ) : (
                      <div className={s.statusActions}>
                        <span className={s.statusTitle}>Соответствует?</span>
                        <div className={s.statusBtns}>
                          <button
                            onClick={() => setPopupEditStatus(brandItem)}
                            className={'btn green small'}
                          >
                            да
                          </button>
                          <button
                            onClick={() => setPopupEditStatus(brandItem)}
                            className={'btn red small'}
                          >
                            нет
                          </button>
                        </div>
                      </div>
                    )} */}

                    {/* <p className={s.status}>Соответствует</p>  */}
                  </div>
                </li>
              ))}
          </ul>
        )}
        {popupEditShowed && (
          <Popup
            //   className={s.popup}
            windowClassName={s.popup}
            close={() => setPopupEditShowed(false)}
            title={`Изменение названия ${brand.brand_name}`}
          >
            <Input
              placeholder=""
              val={brand.brand_name}
              className={s.popupInput}
              label={'Название бренда'}
              setVal={() => console.log()}
            />
            <div className={s.btns}>
              <button
                className="btn light"
                onClick={() => setPopupEditShowed(false)}
              >
                Отмена
              </button>
              <button
                className="btn dark"
                onClick={() => setPopupEditShowed(false)}
              >
                Сохранить
              </button>
            </div>
          </Popup>
        )}
        {popupEditStatus && (
          <Popup
            loading={statusUpdating}
            windowClassName={s.popup + ' ' + s.status}
            close={() => setPopupEditStatus()}
            title={
              +popupEditStatus.status
                ? +popupEditStatus.status === 1
                  ? `${popupEditStatus.title} соответствует бренду`
                  : `${popupEditStatus.title} не соответствует бренду`
                : `${popupEditStatus.title} соответствует бренду?`
            }
          >
            {!+popupEditStatus.status && (
              <div className={s.btns}>
                <button
                  className="btn light"
                  onClick={(e) => {
                    e.stopPropagation();
                    updateStatus(popupEditStatus, 2);
                  }}
                >
                  Нет
                </button>
                <button
                  className="btn  dark"
                  onClick={(e) => {
                    e.stopPropagation();
                    updateStatus(popupEditStatus, 1);
                  }}
                >
                  Соответствует
                </button>
              </div>
            )}
          </Popup>
        )}
      </li>
    )
  );
};

export default BrandItem;
