import auth from './auth';
import brands from './brands';
import products from './products';
import categories from './categories';

const api = {
  auth,
  brands,
  products,
  categories,
};

export default api;
