import Endpoints from '../endpoints';
import { axiosInstance } from '../instance';
// import fakeProducts from './fakeProducts.json';

export const getList = async (params) => {
  try {
    const res = await axiosInstance.get(Endpoints.PRODUCTS.GET_LIST, {
      params: params,
    });
    if (!res.data.error) {
      return res.data;
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log(e);
    return { error: 'Не удалось получить список товаров' };
  }
};

export const getListBySubCat = async ({ subcat_id }) => {
  try {
    const res = await axiosInstance.get(
      Endpoints.PRODUCTS.GET_LIST + `/${subcat_id}/all`
    );
    if (!res.data.error) {
      return res.data;
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log(e);
    return { error: 'Не удалось получить список товаров' };
  }
};

export const getListByBrand = async ({ cat_id, subcat_id, brand }) => {
  try {
    const res = await axiosInstance.get(
      Endpoints.PRODUCTS.GET_LIST + `/${cat_id}/${subcat_id}/${brand}`
    );
    if (!res.data.error) {
      return res.data;
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log(e);
    return { error: 'Не удалось получить список товаров' };
  }
};

// export const getSubListByBrand = async ({ cat_id, subcat_id, brand }) => {
//   try {
//     const res = await axiosInstance.get(
//       Endpoints.PRODUCTS.GET_LIST + `/${cat_id}/${subcat_id}/${brand}`
//     );
//     if (!res.data.error) {
//       return res.data;
//     } else {
//       throw new Error();
//     }
//   } catch (e) {
//     console.log(e);
//     return { error: 'Не удалось получить список товаров' };
//   }
// };

export const getAllListByBrand = async ({ cat_id, subcat_id, brand }) => {
  try {
    const res = await axiosInstance.get(
      Endpoints.PRODUCTS.GET_LIST + `/${cat_id}/${subcat_id}/${brand}/all`
    );
    if (!res.data.error) {
      return res.data;
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log(e);
    return { error: 'Не удалось получить список товаров' };
  }
};

export const getListSlaves = async ({ cat_id, subcat_id, brand, prod_id }) => {
  try {
    const res = await axiosInstance.get(
      Endpoints.PRODUCTS.GET_LIST +
        `/${cat_id}/${subcat_id}/${brand}/${prod_id}/slaves`
    );
    if (!res.data.error) {
      return res.data;
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log(e);
    return { error: 'Не удалось получить список товаров' };
  }
};

export const updateStatus = async (data) => {
  try {
    const res = await axiosInstance.post(
      Endpoints.PRODUCTS.UPDATE_STATUS,
      data
    );
    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log(e);
    return { error: 'Не удалось обновить статус товара' };
  }
};

export const makeMaster = async (item_id) => {
  try {
    const res = await axiosInstance.post(Endpoints.PRODUCTS.MAKE_MASTER, {
      item_id,
    });
    console.log(res);
    // if (res.data.status === 'successful') {
    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log(e);
    return { error: 'Не удалось обновить статус товара' };
  }
};

export const delMaster = async (item_id) => {
  try {
    const res = await axiosInstance.delete(Endpoints.PRODUCTS.DEL_MASTER, {
      data: { item_id },
    });
    console.log(res);
    // if (res.data.status === 'successful') {
    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error();
    }
  } catch (e) {
    // Something went wrong
    return { error: e?.response?.data?.message || 'Не удалось изменить товар' };
  }
};

const products = {
  getList,
  delMaster,
  makeMaster,
  updateStatus,
  getListSlaves,
  getListByBrand,
  // getSubListByBrand,
  getListBySubCat,
  getAllListByBrand,
};

export default products;
