// import api from '../../api/index.js';
import s from './Products.module.scss';
// import arrowIcon from '../../assets/icons/arrow.js';
// import Loader from '../../components/Loader/Loader.jsx';

const SubProductItem = ({ product, changeStatus, activeProduct, toMaster }) => {
  const getPercentClass = (percent) => {
    if (percent < 50) return s.red;
    else if (percent < 75) return s.yellow;
    else return s.green;
  };

  return (
    <li className={s.catWrap}>
      <div
        className={
          s.cat +
          ' ' +
          (+product.status === 1 ? s.active : '') +
          ' ' +
          s.withBtnMaster
        }
        // onClick={() => activeProduct._id && changeStatus(product)}
      >
        <div className={s.catNameWrap}>
          {product.percent && (
            <p
              className={s.catPercent + ' ' + getPercentClass(product.percent)}
            >
              {product.percent}%
            </p>
          )}
          <h3 className={s.catName}>{product.title}</h3>
          {activeProduct._id && (
            <button
              onClick={() => changeStatus(product)}
              className={
                'btn small ' +
                (+product.status === 1 ? 'red ' : 'dark ') +
                s.btnMaster
              }
            >
              {+product.status === 1 ? 'Удалить связь' : 'Cоотнести'}
            </button>
          )}
          {!activeProduct._id && (
            <button
              onClick={() => toMaster(product)}
              className={'btn dark small ' + s.btnMaster}
            >
              Сделать мастером
            </button>
          )}
        </div>
        <ul className={s.catStickers}>
          {product.api_name && (
            <li className={s.sticker + ' ' + s.yellow}>
              api: {product.api_name}
            </li>
          )}
          {(product._id || product.item_products_id) && (
            <li className={s.sticker + ' ' + s.violet}>
              id: {product._id || product.item_products_id}
            </li>
          )}
        </ul>
      </div>
    </li>
  );
};

export default SubProductItem;
