import api from '../../api/index.js';
import s from './Brands.module.scss';
import BrandItem from './BrandItem.jsx';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Header from '../../components/Header/Header.jsx';
import Loader from '../../components/Loader/Loader.jsx';

const Brands = () => {
  let [searchParams] = useSearchParams();
  const [listData, setListData] = useState({
    total: '...',
    single: '...',
    compared: '...',
  });
  const [sort, setSort] = useState(1);
  const [brands, setBrands] = useState([]);
  const [detailing, setDetailing] = useState();
  const [loading, setLoading] = useState(false);
  const [singleBrands, setSingleBrands] = useState([]);
  const [sortedBrands, setSortedBrands] = useState([]);
  const [comparedBrands, setComparedBrands] = useState([]);
  const [sortedSingleBrands, setSortedSingleBrands] = useState([]);
  const [sortedComparedBrands, setSortedComparedBrands] = useState([]);
  // const [totalPage, setTotalPage] = useState(1);
  const [queryParams, setQueryParams] = useState({
    page_size: 10,
    page: 1,
  });

  const sorting = (brands, key) => {
    return brands.sort((a, b) => {
      if (sort === 1) {
        if (a.brand_name.toLowerCase() < b.brand_name.toLowerCase()) {
          return -1;
        }
        if (b.brand_name.toLowerCase() > a.brand_name.toLowerCase()) {
          return 1;
        }
        return 0;
      } else {
        if (a.brand_name.toLowerCase() > b.brand_name.toLowerCase()) {
          return -1;
        }
        if (b.brand_name.toLowerCase() < a.brand_name.toLowerCase()) {
          return 1;
        }
        return 0;
      }
    });
  };

  const sortBrands = (brands) => {
    const ruBrands = brands.filter((brand) =>
      /^[а-яА-ЯёЁ]+$/.test(brand.brand_name[0])
    );
    const enBrands = brands.filter((brand) =>
      /^[a-zA-Z]+$/.test(brand.brand_name[0])
    );
    const otherBrands = brands.filter(
      (brand) =>
        !/^[a-zA-Z]+$/.test(brand.brand_name[0]) &&
        !/^[а-яА-ЯёЁ]+$/.test(brand.brand_name[0])
    );
    const sortedBrands = [
      ...sorting(ruBrands, 'ru'),
      ...sorting(enBrands, 'en'),
      ...sorting(otherBrands),
    ];
    return sortedBrands;
  };

  useEffect(() => {
    const sortedBrands = sortBrands(singleBrands);
    setSortedSingleBrands(sortedBrands);
  }, [singleBrands, sort]);

  useEffect(() => {
    const sortedBrands = sortBrands(comparedBrands);
    setSortedComparedBrands(sortedBrands);
  }, [comparedBrands, sort]);

  useEffect(() => {
    if (brands.length) {
      const withSlaves = brands.filter((b) => b.slaves.length > 0);
      const withoutSlaves = brands.filter((b) => b.slaves.length === 0);
      const sortedBrands1 = sortBrands(withSlaves);
      const sortedBrands2 = sortBrands(withoutSlaves);
      setSortedBrands([...sortedBrands1, ...sortedBrands2]);
    } else {
      setSortedBrands([]);
    }
  }, [sort, brands]);

  useEffect(() => {
    const page = searchParams.get('page');
    if (page && +page !== queryParams.page) {
      setQueryParams((prev) => ({ ...prev, page: +page }));
    }
  }, [searchParams, queryParams.page]);

  const getListData = (list) => {
    // console.log(list);
    let total = 0;
    let single = 0;
    let compared = 0;
    const singleBrands = [];
    const comparedBrands = [];
    for (let item of list) {
      total += 1 + item.slaves.length;
      if (!item.has_master && item.slaves.length === 0) {
        single++;
        singleBrands.push(item);
      }
      if (item.slaves.length) {
        compared++;
        comparedBrands.push(item);
      }
    }
    setComparedBrands(() => [...comparedBrands]);
    setSingleBrands(() => [...singleBrands]);
    setListData(() => ({
      total,
      single,
      compared,
    }));
  };

  const getBrands = async () => {
    setLoading(true);
    const data = await api.brands.getList(); //queryParams
    // const data2 = await api.brands.getCompareList(); //queryParams
    // console.log(data2);
    if (data.brands) {
      getListData(data.brands);
      console.log();
      setBrands([...data.brands.filter((brand) => !brand.has_master)]);
      // setTotalPage(data.pages_count);
    }
    setLoading(false);
  };

  useEffect(() => {
    getBrands();
    // eslint-disable-next-line
  }, [queryParams]);

  // const updateStatusInList = (prod, prodItem, statusVal) => {
  //   try {
  //     const updatedProdIndex = brands.findIndex((p) => p.SK === prod.SK);
  //     const updatedProdItemIndex = prod.list_of_items.findIndex(
  //       (pi) => pi.SK === prodItem.SK
  //     );
  //     const updatedProdItem = {
  //       ...prodItem,
  //       status: String(statusVal),
  //     };
  //     const updatedProd = {
  //       ...prod,
  //       list_of_items: [
  //         ...prod.list_of_items.slice(0, updatedProdItemIndex),
  //         updatedProdItem,
  //         ...prod.list_of_items.slice(updatedProdItemIndex + 1),
  //       ],
  //     };
  //     setBrands((prev) => [
  //       ...prev.slice(0, updatedProdIndex),
  //       updatedProd,
  //       ...prev.slice(updatedProdIndex + 1),
  //     ]);
  //   } catch (e) {
  //     console.log('updateStatusInList error');
  //   }
  // };

  const copy = (e) => {
    console.log(e.target.innerText);
    navigator.clipboard
      .writeText('e.target.innerText')
      .then(() => console.log('Успешно скопировано'))
      .catch((err) => console.error(err));
  };
  // useEffect(() => {
  //   api.brands.getCompareList();
  // }, []);

  return (
    <>
      <Header
        title={'Бренды'}
        breadCrumbs={[
          { name: 'Главная', link: '/' },
          { name: 'Бренды', link: '/brands' },
        ]}
      />
      {!loading ? (
        <>
          <div className={s.listDataWrap}>
            <div className={s.listData}>
              <span className={s.listDataTitle}>Всего:</span>
              <span className={s.listDataNumb}>{listData.total}</span>
            </div>
            <button
              onClick={() =>
                detailing === 'compared'
                  ? setDetailing()
                  : setDetailing('compared')
              }
              className={
                s.listData +
                ' ' +
                (detailing === 'compared' ? ' ' + s.active : '')
              }
            >
              <span className={s.listDataTitle}>Сопоставленных:</span>
              <span className={s.listDataNumb}>{listData.compared}</span>
            </button>
            <button
              onClick={() =>
                detailing === 'single' ? setDetailing() : setDetailing('single')
              }
              className={
                s.listData +
                ' ' +
                (detailing === 'single' ? ' ' + s.active : '')
              }
            >
              <span className={s.listDataTitle}>Одиночных:</span>
              <span className={s.listDataNumb}>{listData.single}</span>
            </button>
          </div>
          {!detailing && (
            <div className={s.brands + ' ' + (!brands.length ? s.empty : ' ')}>
              <div>
                <button
                  onClick={() => setSort((prev) => (prev === 1 ? 2 : 1))}
                  className="btn"
                >
                  {sort === 1 ? 'Сортировка A-Я' : 'Сортировка Я-А'}
                </button>
              </div>
              {sortedBrands.map((brand, i) => (
                <BrandItem
                  key={i}
                  brand={brand}
                  // updateStatusInList={updateStatusInList}
                />
              ))}
              {/* {!brands.length && <p>Бренды не найдены</p>} */}
            </div>
          )}
          {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
          {detailing === 'single' && (
            <div className={s.brands + ' ' + (!brands.length ? s.empty : ' ')}>
              <table className={s.detailingTable}>
                <thead>
                  <tr>
                    <th>
                      <button
                        onClick={() => setSort((prev) => (prev === 1 ? 2 : 1))}
                      >
                        название {sort === 1 ? '(A-Я)' : '(Я-А)'}
                      </button>
                    </th>
                    <th>api</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedSingleBrands.map((brand, i) => (
                    <tr key={i}>
                      <td>{brand.brand_name}</td>
                      <td>{brand.api_name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* {!brands.length && <p>Бренды не найдены</p>} */}
            </div>
          )}
          {detailing === 'compared' && (
            <div className={s.brands + ' ' + (!brands.length ? s.empty : ' ')}>
              <table className={s.detailingTable}>
                <thead>
                  <tr>
                    <th>
                      <button
                        onClick={() => setSort((prev) => (prev === 1 ? 2 : 1))}
                      >
                        название {sort === 1 ? '(A-Я)' : '(Я-А)'}
                      </button>
                    </th>
                    <th>api</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedComparedBrands.map((brand, i) => (
                    <tr key={i}>
                      <td>{brand.brand_name}</td>
                      <td>{brand.api_name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* {!brands.length && <p>Бренды не найдены</p>} */}
            </div>
          )}

          {/* <Pagination totalPage={totalPage} currentPage={queryParams.page} /> */}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Brands;
