import api from '../../api/index.js';
import s from './Products.module.scss';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/Loader/Loader.jsx';
import Header from '../../components/Header/Header.jsx';

const ProductBrands = () => {
  const navigate = useNavigate();
  const [brands, setBrands] = useState([]);
  const { cat_id, subcat_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [subCategory, setSubCategory] = useState({});

  const setBrand = (brand) => {
    navigate(brand);
  };

  const getBrands = async () => {
    setLoading(true);
    const data = await api.brands.getListByCategory({
      subcategory_id: subcat_id,
    });
    if (data.brands) {
      setBrands(data.brands);
    }
    const subCatData = await api.categories.getFirstSubList(cat_id);
    if (subCatData?.categories) {
      console.log(subCatData?.categories);
      const cat = subCatData.categories.find(
        (cat) => cat.api_category_id === subcat_id
      );
      cat && setSubCategory(cat);
    }
    setLoading(false);
  };

  useEffect(() => {
    getBrands();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={s.page}>
      <Header
        title={
          'Сопоставление товаров. Бренды подкатегории ' +
          (subCategory.category_name || '')
        }
        breadCrumbs={[
          { name: 'Главная', link: '/' },
          { name: 'Сопоставление товаров. Категории', link: '/products' },
          {
            name: 'Бренды подкатегории ' + (subCategory.category_name || ''),
            link: '/products',
          },
        ]}
      />
      <div className={s.brandsContainer}>
        <div className={s.brandsWrap}>
          {!loading ? (
            <ul className={s.itemsList}>
              {brands.map(
                (brand, i) =>
                  brand?.brand_name && (
                    <li
                      key={i}
                      className={s.item}
                      onClick={() => setBrand(brand.brand_id)}
                    >
                      {brand.brand_name}
                    </li>
                  )
              )}
              {brands.length === 0 && <p>Пусто</p>}
            </ul>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductBrands;
