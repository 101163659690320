import api from '../../api/index.js';
import s from './Products.module.scss';
import { useEffect, useState } from 'react';
import ProductList from './ProductList.jsx';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header/Header.jsx';
import PopupAgreement from '../../components/Popup/PopupAgreement.jsx';

const Products = () => {
  const { cat_id, subcat_id, brand } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  // const [allProducts, setAllProducts] = useState([]);
  // const [sortedAllProducts, setSortedAllProducts] = useState([]);
  const [subProducts, setSubProducts] = useState([]);
  const [subSortedProducts, setSubSortedProducts] = useState([]);
  const [subLoading, setSubLoading] = useState(false);
  const [activeProduct, setActiveProduct] = useState({});
  const [subCategory, setSubCategory] = useState({});
  const [brandName, setBrandName] = useState('');
  const [masterToDel, setMasterToDel] = useState();
  const [masterToDelErr, setMasterToDelErr] = useState('');

  const getProducts = async () => {
    setLoading(true);
    const data = await api.products.getListByBrand({
      cat_id: cat_id,
      subcat_id: subcat_id,
      brand: brand,
    });
    // all
    // const allProdsData = await api.products.getAllListByBrand({
    //   brand: brand,
    //   cat_id: cat_id,
    //   subcat_id: subcat_id,
    // });
    // if (allProdsData.goods) {
    //   setAllProducts(allProdsData.goods);
    // }
    //
    if (data.goods) {
      setProducts(data.goods);
    }
    const subCatData = await api.categories.getFirstSubList(cat_id);
    if (subCatData?.categories) {
      const cat = subCatData.categories.find(
        (cat) => cat.api_category_id === subcat_id
      );
      cat && setSubCategory(cat);
    }
    const brandData = await api.brands.getListByCategory({
      subcategory_id: subcat_id,
    });
    if (brandData?.brands) {
      const brandName = brandData.brands.find((b) => b.brand_id === brand);
      brandName && setBrandName(brandName.brand_name);
    }
    setLoading(false);
  };

  const getSubProducts = async () => {
    setSubLoading(true);
    if (activeProduct._id) {
      const dataSlaves = await api.products.getListSlaves({
        brand: brand,
        cat_id: cat_id,
        subcat_id: subcat_id,
        prod_id: activeProduct._id,
      });
      const data = await api.products.getAllListByBrand({
        brand: brand,
        cat_id: cat_id,
        subcat_id: subcat_id,
        // prod_id: activeProduct._id,
      });
      if (dataSlaves.goods && data.goods) {
        console.log(data.goods);
        setSubProducts([
          ...dataSlaves.goods,
          ...data.goods.filter((goodsItem) => !goodsItem.has_master),
        ]);
      }
    } else {
      const data = await api.products.getAllListByBrand({
        brand: brand,
        cat_id: cat_id,
        subcat_id: subcat_id,
      });
      console.log('all ', data.goods);
      if (data.goods) {
        setSubProducts([
          ...data.goods.filter((goodsItem) => !goodsItem.has_master),
        ]);
      }
    }
    setSubLoading(false);
  };

  useEffect(() => {
    if (cat_id && brand) {
      getSubProducts();
    } else {
      setSubLoading(false);
    }
    // eslint-disable-next-line
  }, [activeProduct, cat_id, brand]);

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line
  }, []);

  // const updateStatusInList = (prod, prodItem, statusVal) => {
  //   try {
  //     const updatedProdIndex = products.findIndex((p) => p.SK === prod.SK);
  //     const updatedProdItemIndex = prod.list_of_items.findIndex(
  //       (pi) => pi.SK === prodItem.SK
  //     );
  //     const updatedProdItem = {
  //       ...prodItem,
  //       status: String(statusVal),
  //     };
  //     const updatedProd = {
  //       ...prod,
  //       list_of_items: [
  //         ...prod.list_of_items.slice(0, updatedProdItemIndex),
  //         updatedProdItem,
  //         ...prod.list_of_items.slice(updatedProdItemIndex + 1),
  //       ],
  //     };
  //     setProducts((prev) => [
  //       ...prev.slice(0, updatedProdIndex),
  //       updatedProd,
  //       ...prev.slice(updatedProdIndex + 1),
  //     ]);
  //   } catch (e) {
  //     console.log('updateStatusInList error');
  //   }
  // };

  const changeStatus = async (product) => {
    try {
      console.log(product);
      console.log(activeProduct);
      if (!product._id && !product.item_products_id) return;
      const newStatus = !product.status ? 1 : 0;
      const data = {
        status: newStatus,
        api_name: product.api_name,
        brand_id: product.brand_id,
        parent_id: activeProduct._id,
        category_id: product.category_id,
        item_products_id: product._id || product.item_products_id,
      };
      const resp = await api.products.updateStatus(data);
      if (resp?.status !== 'updated') {
        alert('Не удалось обновить статус');
        throw new Error();
      }
      const editProdIndex = subProducts.findIndex((prod) =>
        product._id
          ? prod._id === product._id
          : prod.item_products_id === product.item_products_id
      );

      console.log(editProdIndex);

      const editedProd = {
        ...subProducts[editProdIndex],
        status: newStatus,
      };
      setSubProducts((prev) => [
        ...prev.slice(0, editProdIndex),
        editedProd,
        ...prev.slice(editProdIndex + 1),
      ]);

      // product
      const editMainProdIndex = products.findIndex(
        (prod) => prod._id === activeProduct._id
      );

      const editedMainProd = {
        ...products[editMainProdIndex],
        slaves_count: !newStatus
          ? products[editMainProdIndex].slaves_count - 1
          : products[editMainProdIndex].slaves_count + 1,
      };
      setProducts((prev) => [
        ...prev.slice(0, editMainProdIndex),
        editedMainProd,
        ...prev.slice(editMainProdIndex + 1),
      ]);
    } catch (e) {
      console.log('Ошибка при изменении статуса');
    }
  };

  const sortSubProducts = () => {
    setSubSortedProducts([
      ...subProducts.filter((good) =>
        good.title.toLowerCase().includes(searchVal.toLowerCase())
      ),
    ]);
    // setSortedAllProducts([
    //   ...allProducts.filter((good) =>
    //     good.title.toLowerCase().includes(searchVal.toLowerCase())
    //   ),
    // ]);
  };

  useEffect(() => {
    if (searchVal) {
      sortSubProducts(searchVal);
    } else {
      setSubSortedProducts([...subProducts]);
      // setSortedAllProducts([...allProducts]);
    }
  }, [searchVal, subProducts]);

  const toMaster = async (prod) => {
    const resp = await api.products.makeMaster(prod._id);
    if (resp.products_master_id) {
      // setAllProducts([...allProducts.filter((p) => p._id !== prod._id)]);
      setSubProducts((prev) => [...prev.filter((p) => p._id !== prod._id)]);
      setProducts([
        ...products,
        { ...prod, _id: resp.products_master_id, slaves: [] },
      ]);
    } else {
    }
  };

  const delMaster = async (prod) => {
    const resp = await api.products.delMaster(prod._id);
    if (resp.error) {
      setMasterToDelErr(resp.error);
      // setTimeout(() => {
      //   setMasterToDelErr('');
      // }, 2000);
    }
    if (resp.item_id) {
      // setAllProducts([...allProducts.filter((p) => p._id !== prod._id)]);
      setProducts((prev) => [...prev.filter((p) => p._id !== prod._id)]);
      setSubProducts((prev) => [...prev, { ...prod, _id: resp.item_id }]);
      setMasterToDel();
    }
  };

  useEffect(() => {
    if (!masterToDel && masterToDelErr) {
      setMasterToDelErr('');
    }
  }, [masterToDel]);

  return (
    <div className={s.page}>
      <Header
        title={'Сопоставление товаров бренда ' + brandName}
        breadCrumbs={[
          { name: 'Главная', link: '/' },
          { name: 'Сопоставление товаров. Категории', link: `/products` },
          {
            name: 'Бренды подкатегории ' + (subCategory.category_name || ''),
            link: `/products/${cat_id}/${subcat_id}`,
          },
          { name: 'Сопоставление товаров' },
        ]}
      />
      <ProductList
        loading={loading}
        products={products}
        toMaster={toMaster}
        searchVal={searchVal}
        subLoading={subLoading}
        setSearchVal={setSearchVal}
        changeStatus={changeStatus}
        activeProduct={activeProduct}
        subProducts={subSortedProducts}
        // allProducts={sortedAllProducts}
        delMaster={(prod) => {
          setMasterToDel(prod);
          prod?.slaves_count > 0 &&
            setMasterToDelErr(
              'У этого мастера есть слейвы. Необходимо сначала удалить их.'
            );
        }}
        setActiveProduct={(prod) => {
          setSubLoading(true);
          setActiveProduct(prod);
        }}
      />
      {masterToDel && (
        <PopupAgreement
          errMes={masterToDelErr}
          close={() => setMasterToDel()}
          agree={() => delMaster(masterToDel)}
          label={`Удалить товар "${
            products.find((prod) => prod._id === masterToDel._id)?.title || ''
          }" из мастеров?`}
        />
      )}
    </div>
  );
};

export default Products;
