import React from 'react';
import './styles/index.scss';
import { store } from './store';
import './styles/normalize.scss';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import Products from './pages/Products/Products.jsx';
import Auth from './pages/Auth/Auth.jsx';
import ErrorPage from './pages/Error/Error.jsx';
import AuthLayout from './layouts/AuthLayout.jsx';
import NotFound from './pages/NotFound/NotFound.jsx';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Categories from './pages/Categories/Categories.jsx';
import Menu from './pages/Menu/Menu.jsx';
import Brands from './pages/Brands/Brands.jsx';
import Analytics from './pages/Analytics/Analytics.jsx';
import Category from './pages/Categories/Category.jsx';
import ProductCategories from './pages/Products/ProductCategories.jsx';
import ProductBrands from './pages/Products/ProductBrands.jsx';
import CatProducts from './pages/Categories/CatProducts.jsx';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <AuthLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <Menu />,
      },
      {
        path: 'categories',
        element: <Categories />,
      },
      { path: 'categories/:cat_id', element: <Category /> },
      {
        path: 'categories/:cat_id/:subcat_id/products',
        element: <CatProducts />,
      },
      {
        path: 'products',
        element: <ProductCategories />,
      },
      {
        path: `products/:cat_id/:subcat_id`,
        element: <ProductBrands />,
      },
      {
        path: `products/:cat_id/:subcat_id/:brand`,
        element: <Products />,
      },
      {
        path: 'brands',
        element: <Brands />,
      },
      {
        path: 'analytics',
        element: <Analytics />,
      },
    ],
  },
  {
    path: '/auth',
    element: <Auth />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
  // </React.StrictMode>
);
