import React from 'react';
import s from './NotFound.module.scss';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className={s.error__wrap}>
      <i className={s.error__img}></i>
      <h1 className={s.error__title}>Страница не найдена</h1>
      <p className={s.error__text}>
        Проверьте url-адрес или перейдите на главную страницу
      </p>
      <Link to="/" className="btn light">
        На главную
      </Link>
    </div>
  );
};

export default NotFound;
