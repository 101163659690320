import Endpoints from '../endpoints';
import { axiosInstance } from '../instance';

export const getList = async (params) => {
  try {
    const res = await axiosInstance.get(Endpoints.BRANDS.GET_LIST, {
      params: params,
    });
    if (!res.data.error) {
      return res.data;
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log(e);
    return { error: 'Не удалось получить список товаров' };
  }
};

export const getListByCategory = async ({ subcategory_id }) => {
  try {
    const res = await axiosInstance.get(
      Endpoints.BRANDS.GET_LIST_BY_CAT + `/${subcategory_id}/brands`
    );
    if (!res.data.error) {
      return res.data;
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log(e);
    return { error: 'Не удалось получить список товаров' };
  }
};

// export const getWGListByCategory = async ({ category_id, subcategory_id }) => {
//   try {
//     const res = await axiosInstance.get(
//       Endpoints.BRANDS.GET_WG_LIST_BY_CAT + `/${subcategory_id}/brands`
//     );
//     if (!res.data.error) {
//       return res.data;
//     } else {
//       throw new Error();
//     }
//   } catch (e) {
//     console.log(e);
//     return { error: 'Не удалось получить список товаров' };
//   }
// };

export const getCompareList = async (params) => {
  try {
    const res = await axiosInstance.get(Endpoints.BRANDS.GET_COMPARE_LIST, {
      params: params,
    });
    if (!res.data.error) {
      return res.data;
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log(e);
    return { error: 'Не удалось получить список товаров' };
  }
};

// export const updateStatus = async (data) => {
//   try {
//     const res = await axiosInstance.post(
//       Endpoints.BRANDS.UPDATE_STATUS,
//       data
//     );
//     if (res.data.status === 'updated') {
//       return res.data;
//     } else {
//       throw new Error();
//     }
//   } catch (e) {
//     console.log(e);
//     return { error: 'Не удалось обновить статус товара' };
//   }
// };

const brands = {
  getList,
  getCompareList,
  getListByCategory,
  // getWGListByCategory,
  // updateStatus,
};

export default brands;
