import { useState } from 'react';
import s from './Input.module.scss';
import eyeOffIcon from '../../assets/icons/eyeOffIcon';
import eyeOnIcon from '../../assets/icons/eyeOnIcon';

const Input = ({
  val,
  unit,
  error,
  label,
  setVal,
  name = '',
  type = 'text',
  className = '',
  placeholder = '',
}) => {
  const [pasIsHidden, setPasIsHidden] = useState(true);

  return (
    <div
      className={s.inputWrap + ' ' + (error ? s.error : '') + ' ' + className}
    >
      {label && <label>{label}</label>}
      <div
        className={
          s.inputSubWrap +
          ' ' +
          (unit ? s.withUnit : '') +
          (type === 'password' ? s.password : '')
        }
      >
        <input
          name={name}
          value={val || ''}
          placeholder={placeholder}
          onChange={(e) => setVal(e.target.value)}
          type={type !== 'password' ? type : pasIsHidden ? type : 'text'}
        />
        {unit && <p className={s.unit}>{unit}</p>}
        {type === 'password' && (
          <span
            className={s.togglePasTypeBtn}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setPasIsHidden((v) => !v);
            }}
          >
            {pasIsHidden ? eyeOffIcon : eyeOnIcon}
          </span>
        )}
      </div>
      {error && (
        <div className={s.errorMesWrap}>
          <span className={s.errorMes}>
            {Array.isArray(error) ? error.join('. ') : error}
          </span>
        </div>
      )}
    </div>
  );
};

export default Input;
