import Endpoints from '../endpoints';
import { axiosInstance } from '../instance';

const login = (data) => axiosInstance.post(Endpoints.AUTH.LOGIN, data);

const signup = (data) => axiosInstance.post(Endpoints.AUTH.SIGNUP, data);

const refreshToken = (params) =>
  axiosInstance.post(Endpoints.AUTH.REFRESH, JSON.stringify(params));

const checkToken = () => axiosInstance.get(Endpoints.AUTH.CHECK);

const auth = {
  login,
  signup,
  checkToken,
  refreshToken,
};

export default auth;
