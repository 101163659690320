import api from '../../api/index.js';
import s from './Categories.module.scss';
import { useEffect, useState } from 'react';
import CategoryList from './CategoryList.jsx';
import Header from '../../components/Header/Header.jsx';

const Categories = () => {
  const [loading, setLoading] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [categories, setCategories] = useState([]);
  const [subLoading, setSubLoading] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  // const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState({});
  const [subSortedCategories, setSubSortedCategories] = useState([]);
  const [categoryLvl, setCategoryLvl] = useState('Категории 1 уровня');
  const [loadedCategoryIdSlaves, setLoadedCategoryIdSlaves] = useState();

  const [startSubCategories, setStartSubCategories] = useState([]);
  const [startAllSubCategories, setStartAllSubCategories] = useState([]);

  const getCategories = async () => {
    setLoading(true);
    const data = await api.categories.getMasterList();
    if (data.categories) {
      setCategories(data.categories);
    }
    setLoading(false);
  };

  const getSubCategories = async () => {
    setSubLoading(true);
    const data = await api.categories.getFirstList();
    const allCatData = await api.categories.getAll();
    if (data.categories) {
      const apiCategoriesArr = data.categories; // - массив массивов категорий с разных апи
      const categories = [];
      for (let catArr of apiCategoriesArr) {
        if (Array.isArray(catArr)) {
          for (let cat of catArr) {
            categories.push(cat);
          }
        } else {
          categories.push(catArr);
        }
      }
      setStartSubCategories([
        ...categories,
        // .filter((cat) => cat.hasOwnProperty('has_master')),
      ]);
    }
    if (allCatData.categories) {
      const apiCategoriesArr = allCatData.categories;
      const categories = [];
      for (let catArr of apiCategoriesArr) {
        if (Array.isArray(catArr)) {
          for (let cat of catArr) {
            categories.push(cat);
          }
        } else {
          categories.push(catArr);
        }
      }
      setStartAllSubCategories([
        ...categories,
        // .filter((cat) => cat.hasOwnProperty('has_master')),
      ]);
    }
    setSubLoading(false);
  };

  const getCategorySlaves = async (api_category_id) => {
    setSubLoading(true);
    const data = await api.categories.getSlavesList(api_category_id);
    const slavesList = [];
    if (data.categories) {
      for (let catArr of data.categories) {
        if (Array.isArray(catArr)) {
          for (let cat of catArr) {
            slavesList.push({ ...cat, has_master: true });
          }
        } else {
          slavesList.push({ ...catArr, has_master: true });
        }
      }
      // console.log(slavesList);
      // console.log(startSubCategories);
      if (categoryLvl === 'Все категории') {
        setSubCategories([...slavesList, ...startAllSubCategories]);
      } else if (categoryLvl === 'Сопоставленные') {
        setSubCategories([...slavesList]);
      } else {
        // console.log([...slavesList, ...startSubCategories]);
        setSubCategories([...slavesList, ...startSubCategories]);
      }
      setLoadedCategoryIdSlaves(api_category_id);
    }
    setTimeout(() => {
      setSubLoading(false);
    }, 500);
  };

  const sortSubCategories = () => {
    setSubSortedCategories([
      ...subCategories.filter((cat) =>
        cat.category_name.toLowerCase().includes(searchVal.toLowerCase())
      ),
    ]);
  };

  useEffect(() => {
    if (searchVal) {
      sortSubCategories(searchVal);
    } else {
      setSubSortedCategories([...subCategories]);
    }
  }, [searchVal, subCategories]);

  useEffect(() => {
    if (activeCategory.api_category_id) {
      if (
        loadedCategoryIdSlaves !== activeCategory.api_category_id &&
        !subLoading
      ) {
        getCategorySlaves(activeCategory.api_category_id);
      }
    } else {
      setLoadedCategoryIdSlaves();
      setSubCategories([]);
    }
    // eslint-disable-next-line
  }, [activeCategory, loadedCategoryIdSlaves, subLoading]);

  // useEffect(() => {
  //   if (activeCategory.slaves) {
  //   }
  // }, [activeCategory, subSortedCategories]);

  useEffect(() => {
    getCategories();
    getSubCategories();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (activeCategory.api_category_id) {
      getCategorySlaves(activeCategory.api_category_id);
    }
    // eslint-disable-next-line
  }, [categoryLvl]);

  const bindCategory = async (subCat) => {
    // compare
    if (!activeCategory || !subCat) return;
    if (subCat.has_master) {
      return unBindCategory(subCat);
    }

    // console.log(activeCategory);
    // console.log(subCat);

    const body = {
      master_id: activeCategory.api_category_id,
      compared_api_name: subCat.api_name,
      compared_id: subCat._id,
    };
    const data = await api.categories.compare(body);
    if (data.error) return alert(data.error);

    const editCategoryIndex = categories.findIndex(
      (i) => i._id === activeCategory._id
    );
    // console.log('editCategoryIndex ', editCategoryIndex);
    const editSubCategoryIndex = subCategories.findIndex(
      (i) => i._id === subCat._id
    );
    // console.log('editSubCategoryIndex ', editSubCategoryIndex);
    const editStartSubCategoryIndex = startSubCategories.findIndex(
      (i) => i._id === subCat._id
    );
    // console.log('editStartSubCategoryIndex ', editStartSubCategoryIndex);
    const editStartAllSubCategoryIndex = startAllSubCategories.findIndex(
      (i) => i._id === subCat._id
    );
    // console.log('editStartAllSubCategoryIndex ', editStartAllSubCategoryIndex);

    // console.log('editCategoryIndex', editCategoryIndex);

    const editCategory = {
      ...activeCategory,
      slaves: [...activeCategory.slaves, { [subCat._id]: subCat.api_name }],
    };

    // console.log('editCategory ', editCategory);

    const editSubCategory = {
      ...subCat,
      has_master: true,
    };
    setActiveCategory(editCategory);
    setCategories((prev) => [
      ...prev.slice(0, editCategoryIndex),
      editCategory,
      ...prev.slice(editCategoryIndex + 1),
    ]);
    setSubCategories((prev) => [
      ...prev.slice(0, editSubCategoryIndex),
      editSubCategory,
      ...prev.slice(editSubCategoryIndex + 1),
    ]);
    editStartSubCategoryIndex >= 0 &&
      setStartSubCategories((prev) => [
        ...prev.slice(0, editStartSubCategoryIndex),
        ...prev.slice(editStartSubCategoryIndex + 1),
      ]);
    editStartAllSubCategoryIndex >= 0 &&
      setStartAllSubCategories((prev) => [
        ...prev.slice(0, editStartAllSubCategoryIndex),
        ...prev.slice(editStartAllSubCategoryIndex + 1),
      ]);
  };

  const unBindCategory = async (subCat) => {
    // compare
    // console.log('unb');
    // console.log(activeCategory);
    // console.log(subCat);
    const body = {
      master_id: activeCategory.api_category_id,
      compared_api_name: subCat.api_name,
      compared_id: subCat._id,
    };
    const data = await api.categories.deleteCompare(body);
    if (data.error) return alert(data.error);

    const editCategoryIndex = categories.findIndex(
      (i) => i._id === activeCategory._id
    );
    const editSubCategoryIndex = subCategories.findIndex(
      (i) => i._id === subCat._id
    );
    const editSlaveIndex = activeCategory.slaves.findIndex(
      (slave) => Object.keys(slave)[0] === subCat._id
    );

    console.log(editSlaveIndex);

    const editedSlaves = [
      ...activeCategory.slaves.slice(0, editSlaveIndex),
      ...activeCategory.slaves.slice(editSlaveIndex + 1),
    ];

    console.log(activeCategory);
    console.log(subCat);

    const editCategory = {
      ...activeCategory,
      slaves: [...editedSlaves],
    };
    // console.log('editSlaveIndex', editSlaveIndex);
    // console.log('editCategoryIndex', editCategoryIndex);

    const editSubCategory = {
      ...subCat,
      has_master: false,
    };

    setActiveCategory(editCategory);
    setCategories((prev) => [
      ...prev.slice(0, editCategoryIndex),
      editCategory,
      ...prev.slice(editCategoryIndex + 1),
    ]);
    setSubCategories((prev) => [
      ...prev.slice(0, editSubCategoryIndex),
      editSubCategory,
      ...prev.slice(editSubCategoryIndex + 1),
    ]);
    if (!editSubCategory.parent_category_id) {
      setStartSubCategories((prev) => [editSubCategory, ...prev]);
    }
    setStartAllSubCategories((prev) => [editSubCategory, ...prev]);
  };

  return (
    <div className={s.page}>
      <Header
        title="Категории"
        breadCrumbs={[
          { name: 'Главная', link: '/' },
          { name: 'Категории', link: '/categories' },
        ]}
      />
      <CategoryList
        loading={loading}
        searchVal={searchVal}
        subLoading={subLoading}
        categories={categories}
        categoryLvl={categoryLvl}
        setSearchVal={setSearchVal}
        bindCategory={bindCategory}
        activeCategory={activeCategory}
        setCategoryLvl={setCategoryLvl}
        subCategories={subSortedCategories}
        setActiveCategory={setActiveCategory}
        catLvls={['Категории 1 уровня', 'Все категории', 'Сопоставленные']}
      />
    </div>
  );
};

export default Categories;
