// import api from '../../api/index.js';
import Loader from '../../components/Loader/Loader';
import Search from '../../components/Search/Search';
import Select from '../../components/Select/Select';
import s from './Categories.module.scss';
import CategoryItem from './CategoryItem';
import SubCategoryItem from './SubCategoryItem';
// import arrowIcon from '../../assets/icons/arrow.js';
// import Loader from '../../components/Loader/Loader.jsx';

const CategoryList = ({
  catLvls,
  loading,
  searchVal,
  subLoading,
  categories,
  categoryLvl,
  isDetailPage,
  setSearchVal,
  bindCategory,
  subCategories,
  activeCategory,
  setCategoryLvl,
  setActiveCategory,
}) => {
  // const [similarShowed, setSimilarShowed] = useState(false);

  // const getPercentClass = (percent) => {
  //   if (percent < 50) return s.red;
  //   else if (percent < 75) return s.yellow;
  //   else return s.green;
  // };

  return (
    <div className={s.categoriesWrap}>
      <div className={s.categories}>
        {!loading ? (
          <ul className={s.itemsList}>
            {categories.map((category, i) => (
              <CategoryItem
                key={i}
                category={category}
                isDetailPage={isDetailPage}
                activeCategory={activeCategory}
                setActiveCategory={setActiveCategory}
              />
            ))}
          </ul>
        ) : (
          <Loader />
        )}
      </div>

      <div className={s.right}>
        <div className={s.searchWrap}>
          <div className={s.titleWrap}>
            <h3 className={s.title}>
              {isDetailPage ? (
                <span className={s.primary}>Сопоставить подкатегории</span>
              ) : (
                <span className={s.primary}>Сопоставить категории</span>
              )}
              {activeCategory.category_name ? (
                <>
                  <span className={s.primary}> c </span>
                  <span>{activeCategory.category_name}</span>
                </>
              ) : (
                ''
              )}
            </h3>
            {categoryLvl && (
              <Select
                className={s.select}
                selectedItem={categoryLvl}
                handleSelect={setCategoryLvl}
                list={catLvls}
              />
            )}
          </div>
          <Search
            val={searchVal}
            className={s.search}
            setVal={setSearchVal}
            placeholder={'Поиск подкатегорий'}
          />
        </div>
        {activeCategory.api_category_id ? (
          <div className={s.subCategories}>
            {!subLoading ? (
              <ul
                className={
                  s.itemsList +
                  ' ' +
                  (subCategories.length === 0 ? s.empty : '')
                }
              >
                {subCategories.map((category, i) => (
                  <SubCategoryItem
                    key={i}
                    category={category}
                    isDetailPage={isDetailPage}
                    bindCategory={bindCategory}
                    activeCategory={activeCategory}
                  />
                ))}
                {subCategories.length === 0 && <p>Категорий не найдено</p>}
              </ul>
            ) : (
              <Loader />
            )}
          </div>
        ) : (
          <div className={s.subCategoriesEmpty}>
            <h2 className={s.title}>
              Выберите корневую категорию, чтобы начать устанавливать
              соответствие
            </h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryList;
