const pencilIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.0596 3.34342L9.65654 5.9404L3.59697 12H1V9.403L7.0596 3.34342ZM7.92525 2.47777L9.22372 1.17928C9.46281 0.94024 9.85034 0.94024 10.0894 1.17928L11.8207 2.91059C12.0598 3.14964 12.0598 3.53721 11.8207 3.77625L10.5222 5.07473L7.92525 2.47777Z"
      fill="#555555"
    />
  </svg>
);

export default pencilIcon;
