import { createSlice } from '@reduxjs/toolkit';

const withAuth = true;
// const withAuth = false;

const initialState = {
  email: null,
  error: null,
  loading: true,
  accessToken: null,
  isRegistred: false,
  isAuth: withAuth ? false : true,
};

export const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // send email
    signupStart: (state) => ({
      ...state,
      loading: true,
    }),
    signupSuccess: (state) => ({
      ...state,
      loading: false,
      error: null,
      isRegistred: true,
    }),
    signupFailure: (state) => ({
      ...state,
      loading: false,
      error: { email: 'Не удалось зарегистрироваться' },
    }),
    // send key
    loginStart: (state) => ({
      ...state,
      loading: true,
    }),
    loginSuccess: (state, action) => ({
      ...state,
      error: null,
      isAuth: true,
      loading: false,
      accessToken: action.payload,
    }),
    loginFailure: (state) => ({
      ...state,
      loading: false,
      error: { code: 'Пользователь не найден' },
    }),
    // logout
    logoutSuccess: () => ({
      ...initialState,
    }),
    // check auth
    checkSuccess: () => ({
      ...initialState,
      loading: false,
      isAuth: true,
    }),
    checkFailure: () => ({
      ...initialState,
      loading: false,
      isAuth: withAuth ? false : true,
    }),
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  signupStart,
  signupSuccess,
  signupFailure,
  checkSuccess,
  checkFailure,
} = authReducer.actions;

export default authReducer.reducer;
