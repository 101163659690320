import { useState } from 'react';
import s from './Menu.module.scss';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import homeIcon from '../../assets/icons/homeIcon';

const Menu = () => {
  const navigate = useNavigate();
  const [menuItems] = useState([
    { title: 'Соотнести категории', link: '/categories' },
    { title: 'Соотнести бренды', link: '/brands' },
    { title: 'Соотнести товары', link: '/products' },
    { title: 'Аналитика', link: '/analytics' },
  ]);

  return (
    <>
      <Header title="Главная" titleIcon={homeIcon} />
      <div>
        <div className={s.menu}>
          {menuItems.map((item, i) => (
            <div
              key={i}
              className={s.item}
              onClick={() => console.log(item.link)}
            >
              <h3>{item.title}</h3>
              <button
                className="btn grey"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(item.link);
                }}
              >
                Перейти
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Menu;
